import React from "react"

const Footer = () => (
  <footer className="footer">
    <div>
      <p>
        © {new Date().getFullYear()}, Built with
    {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </p>
    </div>
  </footer>
)

export default Footer
