import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"

import Layout from "./layout"
import Article from "./article"

const shortcodes = { Link }

const MDXPage = ({ children }) => (
  <Layout>
    <Article>
      <MDXProvider components={shortcodes}>
        {children}
      </MDXProvider>
    </Article>
  </Layout>
)

export default MDXPage
