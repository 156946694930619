import React from "react"

const Article = ({ children }) => {
  return (
    <article className="article">
      {children}
    </article>
  )
}

export default Article

